@import '@crystal-eyes/constants.scss';

.container {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 0 0 10px;
  position: relative;
  &.uncontained {
    max-width: inherit;
  }
  @media (max-width: 1072px) {
    padding: 0 10px;
  }
  &.uncontainedSmall {
    @media (max-width: 1072px) {
      padding: 0;
    }
  }
  &.flexFill {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
